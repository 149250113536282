import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import GetNotifiedBlock from "../components/Shop/GetNotifiedBlock"
import ProductLandingPageBlock from "../components/Shop/ProductLandingPageBlock"
import colors from "../styles/colors"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from "../components/Layout/Layout"

const PageContainer = styled.div`
  padding-top: calc(4vmax + ${DESKTOP_HEADER_HEIGHT}px);
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 6.6vmax;

  @media (max-width: ${breakpoints.md}) {
    padding-top: calc(4vmax + ${MOBILE_HEADER_HEIGHT}px);
  }
`

const ShopCopyPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulShopPage

  const saleOn =  page.drops

  let sharingImage = false

  if (page.socialMediaImage) {
    sharingImage = page.socialMediaImage.file.url
  }

  return (
    <Layout dark bg={colors.lightGrey}>
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
        path={path}
        image={sharingImage}
      />
      <PageContainer>
        {saleOn ? <ProductLandingPageBlock data={data} /> : <GetNotifiedBlock blocks={page.blocks} />}
      </PageContainer>
    </Layout>
  )
}

export default ShopCopyPage

export const pageQuery = graphql`
  query ShopQuery2($category_id: [SwellProductFieldsEnum]!) {
    contentfulShopPage(slug: { eq: "promo-shop" }) {
      ...shopPageFragment
    }
    allSwellProduct(
      filter: { active: { eq: true } }
      sort: { fields: $category_id }
    ) {
      edges {
        node {
          price
          currency
          name
          slug
          stock_status
          options {
            values {
              name
              price
              id
            }
            name
          }
          images {
            fileLocal {
              childImageSharp {
                gatsbyImageData(aspectRatio: 0.76)
              }
            }
          }
          categories {
            slug
          }
          sale_price
          sale
        }
      }
    }
    allContentfulProduct(filter: {}) {
      edges {
        node {
          title
          slug
          hasVegetarian
          servingSize
          newRelease
          spicy
          blocks {
            __typename
            ... on ContentfulRestaurant {
              name
              
            }
          }
        }
      }
    }
  }
`
