import React, { Fragment } from "react"
import styled from "styled-components"
import CtaButton from "../Core/CtaButton"
import Text from "../Blocks/Text"
import Image from "../Blocks/Image"
import GetNotifiedForm from "../Forms/GetNotifiedForm"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"

const BlockContainer = styled.div`
  h3,
  h3 a {
    color: ${colors.royalBlue};
    margin-top: 2rem;
    font-size: calc(0.6vw + 1rem);
    text-align: center;
  }

  a {
    text-decoration-thickness: 1px;
    text-underline-offset: 0.2em;
  }
`

const ContentSubBlock = styled.div`
  text-align: center;
  margin: auto;
  max-width: 100%;
  width: 100%;
  padding: 17px;

  @media (min-width: ${breakpoints.md}) {
    max-width: 67%;
    padding: 0;
    padding-top: 0;
  }

  h3 {
    color: ${colors.royalBlue};
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-size: calc(0.6vw + 1rem);
    line-height: 1.176;
  }

  h2 {
    color: ${colors.royalBlue};
    margin-bottom: 2rem;
    line-height: 1.104;
  }

  p {
    b {
      i {
        ${fonts.workSansBold}
        font-style: italic;
        font-size: 1rem;
      }
    }
    margin-bottom: 34px;
  }
`

const ImageFormSubBlock = styled.div`
  padding: 17px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    padding: 34px 0;
  }
`

const ImageBlock = styled.div`
  padding: 0 0 34px 0;
  @media (min-width: ${breakpoints.md}) {
    padding: 0;
    padding-right: 34px;
    width: 59%;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-right: 48px;
  }

`

const FormContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    width: 41%;
    align-self: center;
  }
`

const GetNotifiedBlock = (props) => {
  const block = props.blocks[0]

  const { blockHeading, ctaUrl, ctaLabel, image, textBlocks, blockFooterText } =
    block

  return (
    <BlockContainer>
      <ContentSubBlock>
        {blockHeading ? <h2>{blockHeading}</h2> : null}
        {textBlocks
          ? textBlocks.map((textBlock, index) => {
              const { heading, content } = textBlock
              return (
                <Fragment key={index}>
                  {heading ? <h3>{heading}</h3> : null}
                  {content ? <Text content={content} /> : null}
                </Fragment>
              )
            })
          : null}
        {ctaUrl ? (
          <CtaButton
            url={ctaUrl}
            label={ctaLabel ? ctaLabel : ctaUrl}
            blue={true}
          />
        ) : null}
      </ContentSubBlock>
      <ImageFormSubBlock>
        <ImageBlock>
          {image ? <Image image={image} /> : null}
        </ImageBlock>
        <FormContainer>
          <GetNotifiedForm />
        </FormContainer>
      </ImageFormSubBlock>
      {blockFooterText ? <Text content={blockFooterText} /> : null}
    </BlockContainer>
  )
}

export default GetNotifiedBlock
